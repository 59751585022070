<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "bulma-extensions/bulma-steps/dist/css/bulma-steps.min.css";

export default {
  name: "App",
};
</script>

<style lang="scss" scoped>
@import "main.sass";

#app {
  font-family: Open Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
  height: 100%;
}
.is_blue {
  color: #2495df;
}
.is_red {
  color: #f14668;
}
.is_white {
  color: #fff;
}
.is_grey {
  color: #707070;
}
.mdi-chevron-left::before {
    content: "<";
}
.mdi-chevron-right::before {
    content: ">";
}
</style>
